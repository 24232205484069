'use strict';
window.alert = function (msg, type_msg, title, postscriptum) {
	console.log(title);
	title || (title = '');
	postscriptum || (postscriptum = '');
	msg || (msg = 'Ошибка сервера. Повторите попытку позднее.');
	type_msg || (type_msg = 'danger');
	var obj = $('#modal_alert');
	obj.removeClass('info success warning danger notitle').addClass(type_msg);
	if (title) {
		obj.find('.modal-title').html(title).show();
	} else {
		obj.addClass('notitle').find('.modal-title').hide();
	}
	obj.find('.modal-body').html(msg);
	obj.find('.modal-footer').html(postscriptum);
	obj.modal({});
};

var nua = navigator.userAgent;
if (nua.match(/IEMobile\/10\.0/)) {
	let msViewportStyle = document.createElement('style');
	msViewportStyle.appendChild(
			document.createTextNode(
					'@-ms-viewport{width:auto!important}'
			)
	);
	document.querySelector('head').appendChild(msViewportStyle)
}

$(function () {
	$('.askQuestion').on('click', function () {
		var obj = $(this);
		$('#modal_askQuestion .askQuestion_product').html(obj.data('name')).data('id', obj.data('id'));
		$('#modal_askQuestion').modal();
	});

	$('.mainDirector').on('click', function () {
		var obj = $(this),
				url = obj.data('video') + '?autoplay=1';
		obj.html('<iframe width="100%" height="547" src="' + url + '" frameborder="0" allowfullscreen></iframe>');
	});

	$('.asLink').on('click', function (e) {
		var obj = $(e.target),
				stopLink = obj.parents('.stopLink');
		if (obj.hasClass('stopLink') || stopLink.length) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
		var href = obj.parents('.asLink').data('href');
		if (href != null) {
			window.location.href = href;
		}
		return true;
	});

	let isAndroid = (
			nua.indexOf('Mozilla/5.0') > -1
			&& nua.indexOf('Android ') > -1
			&& nua.indexOf('AppleWebKit') > -1
			&& nua.indexOf('Chrome') === -1
	);
	if (isAndroid) {
		$('select.form-control').removeClass('form-control').css('width', '100%');
	}

	var hasSwiper = $('.swiper-container');
	if (hasSwiper.length) {
		var swiperMainSlider = new Swiper('.mainSlider .swiper-container', {
			//paginationClickable: true,
			loop: true,
			autoplay: 2500
		});

		var swiperMainPartners = new Swiper('.mainPartners .swiper-container', {
			slidesPerView: 6,
			spaceBetween: 28,
			//paginationClickable: true,
			loop: true,
			autoplay: 2500
		});

		var swiperMainNews = new Swiper('.mainNews .swiper-container', {
			pagination: '.mainNews .swiper-pagination',
			paginationClickable: true,
			loop: true,
			autoplay: 3000
		});

		var swiperMainNews = new Swiper('.detailSlider .swiper-container', {
			scrollbar: '.detailSlider .swiper-scrollbar',
			scrollbarHide: false,
			scrollbarDraggable: true,
			paginationClickable: true,
			loop: true,
			autoplay: 3000
		});
	}


	setTimeout(function () {
		$('.mainDirector').each(function () {
			$('.mainDirector_text table').height($(this).height());
		});
	}, 300);

	$('.asLink').on('click', function (e) {
		let obj = $(e.target),
				stopLink = obj.parents('.stopLink');
		if (obj.hasClass('stopLink') || stopLink.length) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
		let href = obj.data('href');
		if (href != null) {
			window.location.href = href;
		}
		return true;
	});

});


var app = angular.module('app', ['ui.utils']);

app.directive('onlyDigits', function () {
	return {
		require: 'ngModel',
		restrict: 'A',
		link: function (scope, element, attr, ctrl) {
			function inputValue(val) {
				if (val) {
					var digits = val.replace(/[^0-9]/g, '');
					if (attr.onlyDigits) {
						digits = digits.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
					}
					if (digits !== val) {
						ctrl.$setViewValue(digits);
						ctrl.$render();
					}
					return digits; //parseInt(digits, 10);
				}
				return val;
			}

			ctrl.$parsers.push(inputValue);
		}
	};
});

app.controller('ctrlFeedback', ['$scope', '$http', function ($scope, $http) {
	$scope.blocked_send = false;
	$scope.submit = function () {
		if (!$scope.blocked_send && $scope.formFeedback.$valid) {
			$scope.blocked_send = true;
			$http
					.post('/ajax/feedback.php', $scope.user)
					.success(function (res) {
						$scope.blocked_send = false;
						$scope.user = {};
						$scope.formFeedback.$setPristine();
						alert(res.msg, 'success', res.title);
					})
					.error(function (err) {
						alert(err.msg, 'error', 'Ошибка!');
						$scope.blocked_send = false;
					});
		}
	}
}]);

app.controller('ctrlOrderCall', ['$scope', '$http', function ($scope, $http) {
	$scope.blocked_send = false;
	$scope.submit = function () {
		if (!$scope.blocked_send && $scope.formOrderCall.$valid) {
			$scope.blocked_send = true;
			$http
					.post('/ajax/order_call.php', $scope.user)
					.success(function (res) {
						$scope.blocked_send = false;
						$scope.user = {};
						$scope.formOrderCall.$setPristine();
						$('#orderCall').modal('hide');
						alert(res.msg, 'success', res.title);
					})
					.error(function (err) {
						alert(err.msg, 'error', 'Ошибка!');
						$scope.blocked_send = false;
					});
		}
	}
}]);


app.controller('ctrlProduct', ['$scope', '$http', function ($scope, $http) {
	$scope.product = {};
	$scope.blocked_send = false;
	$scope.addCart = function () {
		$http
				.post('/ajax/addCart.php', $scope.product)
				.success(function (res) {
					$scope.blocked_send = false;
					console.log([res, $scope.product]);
					$('.topLine .topLine_cart').attr('data-count', res.count);
					$scope.product.count = 1;
					$('#modal_addProduct').modal();
				})
				.error(function (err) {
					alert(err.msg, 'error', 'Ошибка!');
					$scope.blocked_send = false;
				});
	};
	$scope.dec = function () {
		if ($scope.product.count == 1) {
			return;
		}
		$scope.product.count--;
	};

	$scope.inc = function () {
		if ($scope.product.count == 999) {
			return;
		}
		$scope.product.count++;
	};
}]);

app.controller('ctrlAskQuestion', ['$scope', '$http', function ($scope, $http) {
	$scope.blocked_send = false;
	$scope.submit = function () {
		if (!$scope.blocked_send && $scope.formAskQuestion.$valid) {
			$scope.user.id = $('#modal_askQuestion .askQuestion_product').data('id');
			$scope.blocked_send = true;
			$http
					.post('/ajax/askQuestion.php', $scope.user)
					.success(function (res) {
						$scope.blocked_send = false;
						$scope.user = {};
						$scope.formAskQuestion.$setPristine();
						$('#modal_askQuestion').modal('hide');
						alert(res.msg, 'success', res.title);
					})
					.error(function (err) {
						alert(err.msg, 'error', 'Ошибка!');
						$scope.blocked_send = false;
					});
		}
	}
}]);

app.controller('ctrlCart', ['$scope', '$http', function ($scope, $http) {
	$scope.cart = window.arCart;
	$scope.blocked_send = false;
	$scope.inc = function (index) {
		if ($scope.cart.items[index].count == 999) {
			return;
		}
		$scope.cart.items[index].count++;
		$scope.calc();
	};
	$scope.dec = function (index) {
		if ($scope.cart.items[index].count == 1) {
			return;
		}
		$scope.cart.items[index].count--;
		$scope.calc();
	};

	$scope.del = function (index) {
		$scope.cart.items.splice(index, 1);
		$scope.calc();
		$('.topLine_cart').attr('data-count', $scope.cart.items.length);
	};

	$scope.calc = function () {
		$scope.cart.total = 0;
		for (let i = 0, len = $scope.cart.items.length; i < len; ++i) {
			$scope.cart.total += $scope.cart.items[i].count * $scope.cart.items[i].price;
		}
		$http.post('/ajax/cart.php', $scope.cart);
	};

	$scope.submit = function() {
		if (!$scope.blocked_send && $scope.formCart.$valid) {
			$scope.blocked_send = true;
			$http
					.post('/ajax/order.php', $scope.user)
					.success(function (res) {
						$scope.blocked_send = false;
						$scope.user = {};
						$scope.cart = {};
						$scope.show_form = false;
						$scope.formCart.$setPristine();
						$('.topLine_cart').attr('data-count', 0);
						alert(res.msg, 'success', res.title);
					})
					.error(function (err) {
						alert(err.msg, 'error', 'Ошибка!');
						$scope.blocked_send = false;
					});
		}
	}
}]);


(function (w) {
	w.onload = function () {
		var i, objects = document.querySelectorAll('.lazy-load-img'),
				len = objects.length,
				options = {
					size: 'cover',
					position: '50%',
					color: 'transparent',
					repeat: 'no-repeat'
				};
		for (i = 0; i < len; i++) {
			var src = objects[i].getAttribute('data-src');
			if (src) {
				var size = objects[i].getAttribute('data-size') || options.size,
						position = objects[i].getAttribute('data-position') || options.position,
						color = objects[i].getAttribute('data-color') || options.color,
						repeat = objects[i].getAttribute('data-repeat') || options.repeat;
				objects[i].style.backgroundImage = 'url(' + src + ')';
				objects[i].style.backgroundColor = color;
				objects[i].style.backgroundPosition = position;
				objects[i].style.backgroundSize = size;
				objects[i].style.backgroundRepeat = repeat;
			}
		}
	}
})(window);

